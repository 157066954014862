<template>
  <div class="auth-wrapper">
    <div class="col-lg-4 mx-auto form-holder">
      <div class="my-5 d-flex align-items-center justify-content-center">
        <img
          :src="
            AppTheme === 'dark_theme'
              ? require('@/assets/img/brand/ana_white.png')
              : require('@/assets/img/brand/ana.png')
          "
          class="logo"
          alt=""
        />
      </div>
      <form @submit.prevent="requestPasswordReset" class="app-form">
        <div class="mt-5">
          <h3 class="text-center ana_h3 ana_text-grey">Reset your password</h3>
          <p class="text-center ana_paragraph ana_text-grey">
            Provide me with the email you created an account with
          </p>
        </div>
        <div class="">
          <InlineAlert
            :alertMessage="authMessage"
            alertType="error"
            v-if="authMessage"
          />
        </div>
        <div class="form-group">
          <label for="email" class="ana-label">Email</label>
          <input
            type="email"
            name="email"
            class="ana-input w-100"
            v-model.trim="$v.email.$model"
            placeholder="Email"
            required
          />
          <InlineAlert
            alertMessage="Be sure to provide a valid email"
            alertType="error"
            v-if="email && $v.email.$invalid"
          />
        </div>
        <div class="mt-3 mb-3 d-flex justify-content-center" v-if="isLoading">
          <div class="linear-loader"></div>
        </div>
        <button
          type="submit"
          class="ana-btn ana-btn__fill w-100"
          :disabled="isLoading || $v.$invalid"
        >
          Reset Password
        </button>
        <div class="mt-5 text-center">
          <p class="ana-text--medium ana_text-grey">
            <router-link to="/login" class="ana_text-secondary">
              Back to Login</router-link
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  name: "ResetPassword",
  metaInfo: {
    title: "Forgot Password - Ana app",
  },
  data() {
    return {
      email: "",
      authMessage: "",
      success: false,
      isLoading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    requestPasswordReset() {
      this.isLoading = true;
      const email = {
        email: this.email,
      };
      axios
        .put("user/forgotPassword", email)
        .then((res) => {
          this.authMessage = "";
          this.success = true;
          this.isLoading = false;
          this.$swal(`${res.data.message}`);
          this.$router.push("/login");
        })
        .catch((error) => {
          this.success = false;
          this.authMessage = error.response.data.message;
          this.isLoading = false;
        });
    },
  },
};
</script>
